@import url('normalize.css');
.animate {
  transition: all 0.2s ease-out;
}
html {
  box-sizing: border-box;
}
body {
  box-sizing: border-box;
  background-color: #11051E;
  color: #DFD2ED;
  font-size: 12px;
  font-family: 'Inconsolata', monospace;
  display: flex;
  min-height: 100vh;
  max-width: 100%;
}
a {
  color: #DFD2ED;
  padding: 8px 6px;
  display: inline-block;
  transition: all 0.2s ease-out;
}
@media screen and (min-width:992px) {
  a {
    padding: 0;
  }
}
a:visited {
  color: #DFD2ED;
}
a:hover {
  color: #6026A1;
}
.wrap {
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 8px;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
@media screen and (min-width:1600px) {
  .wrap {
    overflow: visible;
  }
}
.info {
  text-align: center;
  width: 100vw;
  margin-left: -16px;
  box-sizing: border-box;
  background-color: #6026A1;
  overflow: hidden;
}
@media screen and (min-width:992px) {
  .info {
    margin-left: -8px;
  }
}
@media screen and (min-width:1600px) {
  .info {
    overflow: visible;
    min-width: 100vw;
    margin-left: calc(-50vw + 50%);
  }
}
.info p {
  margin: 0;
  padding: 2px 16px;
}
.info.bottom {
  margin-top: 10px;
}
.info.bottom a:hover {
  color: #DFD2ED;
}
.landing {
  display: flex;
  flex-direction: column;
}
.landing > div {
  box-sizing: border-box;
}
@media screen and (min-width:992px) {
  .landing {
    flex-direction: row;
  }
  .landing > div {
    padding: 1rem;
    flex: 1;
  }
}
.titlewrap {
  display: flex;
}
@media screen and (min-width:992px) {
  .titlewrap {
    justify-content: center;
    align-content: center;
    flex-direction: column;
    border-bottom: 2px solid #6026A1;
    height: 120px;
    position: relative;
  }
}
h1 {
  text-align: center;
  border-bottom: 2px solid #6026A1;
  margin: 0;
  padding: 40px;
  width: 100%;
}
@media screen and (min-width:992px) {
  h1 {
    font-size: 1.8em;
    padding: 0px;
    border-bottom: none;
    max-width: 52rem;
  }
}
h2 {
  margin: 0;
  padding: 40px 40px 0px;
}
ul {
  padding: 0 40px;
  box-sizing: border-box;
}
li {
  list-style-type: none;
  position: relative;
  margin: 0;
  padding: 8px 4px;
  transition: all 0.2s ease-out;
}
li:before {
  transition: all 0.2s ease-out;
  position: absolute;
  content: '';
  display: block;
  left: -16px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  max-height: 12px;
  border-radius: 100%;
  background-color: #6026A1;
  box-sizing: border-box;
  border: 3px solid #6026A1;
}
li:hover {
  color: #ffffff;
}
li:hover:before {
  background-color: #DFD2ED;
}
.action,
.clients {
  padding: 0 40px;
  margin: 40px 0;
}
.action {
  text-align: center;
  font-size: 1.4rem;
}
.action a {
  background-color: #6026A1;
  color: #DFD2ED;
  padding: 2px;
  text-decoration: none;
}
/*# sourceMappingURL=stiil.css.map */